import { DeleteOutlined, SettingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { toast } from "react-toastify";
import { deletePlaceService } from "../../../services/places";
import { confirm } from "../../../components/confirm";

export const PlaceColumns = (setModal, loadBrands, filter) => {
    return [
        {
            name: "İsim",
            sortable: true,
            sortField: "name",
            minWidth: "300px",
            selector: (row) => row.name,
        },
        {
            name: "Marka",
            sortable: true,
            sortField: "brand",
            minWidth: "300px",
            selector: (row) => row?.brand?.name || "Bulunamadı",
        },
        {
            name: "Yönetici",
            sortable: true,
            sortField: "manager",
            minWidth: "300px",
            selector: (row) => row?.manager?.fullname || "Bulunamadı",
        },
        {
            name: "Ekleyen",
            sortable: true,
            sortField: "manager",
            minWidth: "300px",
            selector: (row) => row?.addedBy?.fullname || "Bulunamadı",
        },
        {
          name: "Oluşturma Tarihi",
          sortable: true,
          sortField: "date",
          minWidth: "200px",
          selector: (row) => new Date(parseInt(row.createdAt)).toLocaleDateString(),
        },
        {
            name: "Aksiyonlar",
            sortable: true,
            sortField: "name",
            minWidth: "300px",
            sortable: false,
            selector: (row) => (
                <div>
                    <Button onClick={() => setModal(row)} color="#c72328" icon={<SettingOutlined color="#c72328" />} style={{ marginLeft: "5px" }} />
                    <Button onClick={async () => {
                        if (await confirm("İşletmeyi silmek istediğine emin misin?")){
                            deletePlaceService({ brandId: row.id }).then((res) => {
                                if (!res.data.errors) {
                                    console.log(res.data.data);

                                    toast.warn("İşletme Silindi.");

                                    setModal(null);
                                    loadBrands(filter);
                                }
                            })
                        }
                    }} color="#c72328" icon={<DeleteOutlined color="#c72328" />} style={{ marginLeft: "5px", backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }} />
                </div>
            ),
        },
    ];
};