import React, { useState } from "react";
import { Button, Col, Input, Row } from 'antd';
import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import DataTable from "react-data-table-component";
import { BrandUserColumns } from './columns'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useEffect } from "react";
import { editBrandUserService, loadBrandUsersService, registerBrandUserService } from "../../services/brand-user";
import { toast } from "react-toastify";
import Select from 'react-select';
import { loadRolesService } from "../../services/roles";

const BrandUsers = () => {
    document.title = "Yöneticiler | Sipyo";

    const [data, setData] = useState([]);
    const [roles, setRoles] = useState([]);
    const [filter, setFilter] = useState(null);
    const [modal, setModal] = useState(false);
    
    const loadBrandUsers = () => {
        loadBrandUsersService(filter).then((res) => {
            res = res?.data;
            console.log(res)
            if (!res?.errors) {
                setData(res?.data?.brandUsers);
            }
        })
    }
    
    const loadRoles = () => {
        loadRolesService(filter).then((res) => {
            res = res?.data;
            console.log(res)
            if (!res?.errors) {
                setRoles(res?.data?.roles);
            }
        })
    }

    useEffect(() => {
        loadBrandUsers(filter);
        loadRoles();
    }, []);


    return (
        <React.Fragment>
            <div className="page-content" style={{ borderRadius: "7px" }}>
                <Container fluid={true} style={{ 
                    background: "#3a3a3a", 
                    height: "75px", 
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                }}>
                    <Row gutter={16} justify="space-between" align="middle" style={{ height: "75px" }}>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <Input.Search
                                placeholder="İsim ile ara..."
                                size="large"
                                onSearch={(value) => {
                                    console.log("ara:", value)
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <div style={{ float: "right" }}>
                                <Button
                                    onClick={() => setModal({})}
                                    icon={<PlusOutlined />}
                                    style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                                    size="large"
                                >
                                    Yeni Kullanıcı Ekle
                                </Button>

                            </div>
                        </Col>
                    </Row>
                </Container>

                <DataTable
                    paginationComponentOptions={{ noRowsPerPage: false }}
                    noHeader
                    //pagination
                    columns={BrandUserColumns(setModal, loadBrandUsers, filter)}

                    paginationPerPage={10}
                    className="react-dataTable"
                    sortIcon={<CaretDownOutlined />}
                    //paginationDefaultPage={currentPage}
                    //paginationComponent={DatatableCustomPagination}
                    data={data}
                />
            </div>

            <Modal
                isOpen={!!modal}
                toggle={() => {
                    setModal(false);
                }}
                className="modal-dialog-centered modal-lg"  
            >
                <ModalHeader>
                    {modal?.id ? "Kullanıcı Güncelle" : "Kullanıcı Ekle"}
                </ModalHeader>
                <ModalBody>
                    <Row gutter={12}>
                        <Col xs={12} md={12}>
                            <Label>İsim Soyisim</Label>
                            <Input defaultValue={modal?.fullname} placeholder="İsim Soyisim" onChange={(e) => setModal({...modal, fullname: e.target.value})} />
                        </Col>
                        <Col xs={12} md={12}>
                            <Label>E-Posta</Label>
                            <Input defaultValue={modal?.email} placeholder="E-Posta" onChange={(e) => setModal({...modal, email: e.target.value})} />
                        </Col>
                        <Col xs={12} md={12}>
                            <Label>Rol</Label>
                            <Select
                                options={[
                                    ...roles.map((role) => {return {value: role.id, label: role.title}})
                                ]}
                                defaultValue={{ value: modal?.role?.id, label: modal?.role?.title }}
                                onChange={({value}) => {
                                    setModal({...modal, role: value})
                                }}
                            />
                        </Col>
                        {!modal?.id ? <Col xs={12} md={12}>
                            <Label>Şifre</Label>
                            <Input placeholder="Şifre" onChange={(e) => setModal({...modal, password: e.target.value})} />
                        </Col> : null}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                        onClick={() => {
                            if (modal?.id) {
                                modal.brandUserId = modal.id;
                                if (typeof modal.role == "object") {
                                    modal.role = modal.role.id;
                                }
                                delete modal.id;
                                delete modal.token;
                                delete modal.createdAt;

                                editBrandUserService(modal).then((res) => {
                                    if (!res.data.errors) {
                                        console.log(res.data.data);

                                        toast.success("Kullanıcı Güncellendi.");

                                        setModal(null);
                                        loadBrandUsers(filter);
                                    }
                                })
                            } else {
                                registerBrandUserService(modal).then((res) => {
                                    if (!res.data.errors) {
                                        console.log(res.data.data);

                                        toast.success("Kullanıcı Oluşturuldu.");

                                        setModal(null);
                                        loadBrandUsers(filter);
                                    }
                                })
                            }
                        }}
                    >
                        {modal?.id ? "Kullanıcıyı Güncelle" : "Kullanıcı Ekle"}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default BrandUsers;
