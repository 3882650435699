import React from "react";

import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Dashboard = () => {
  document.title = "Ana Sayfa | Sipyo";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/*<Breadcrumbs title="Sipyo" breadcrumbItem="Ana Sayfa" />*/}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
