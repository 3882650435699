import React, { useEffect } from "react";
import Routes from "./Routes/index";

// ! Toast Messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import Scss
import './assets/scss/theme.scss';

// Fake Backend 
import fakeBackend from "./helpers/AuthType/fakeBackend";
import { verifyTokenService } from "./services/auth";

// Activating fake backend
fakeBackend();

// Firebase
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)


function App() {
  useEffect(() => {
    console.log(window.location.pathname)
    if (window.location.pathname !== "/login" && window.location.pathname !== "/register") {
      verifyTokenService()
    }
  }, [])

  return (
    <React.Fragment>
      <ToastContainer />

      <Routes />
    </React.Fragment>
  );
}

export default App;
