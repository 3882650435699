import axios from "../lib/axios";
const user = JSON.parse(localStorage.getItem("authUser"));

export const loadAdminsService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            query{admins${form && Object?.values(form)?.length > 0 ? `(
                ${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)}
            )`:""}{
                id
                fullname
                email
                token
                createdAt
            }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const registerAdminService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{registerAdmin
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const editAdminService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{editAdmin
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const deleteAdminService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{deleteAdmin
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}