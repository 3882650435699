import axios from "../lib/axios";
const user = JSON.parse(localStorage.getItem("authUser"));

export const createMenuSubCategoryService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{createMenuSubCategory
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                ?.replaceAll('{"', "{")
                .slice(1)
                .slice(0, -1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const updateMenuSubCategoryService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{updateMenuSubCategory
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                ?.replaceAll('{"', "{")
                .slice(1)
                .slice(0, -1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const deleteMenuSubCategoryService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{deleteMenuSubCategory
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}