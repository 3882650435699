import React, { useState } from "react";
import { Button, Checkbox, Col, Input, Row } from 'antd';
import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import DataTable from "react-data-table-component";
import { PlaceColumns } from './columns'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Select from 'react-select';
import { loadBrandUsersService } from "../../services/brand-user";
import { createPlaceService, loadPlacesService, updatePlaceService } from "../../services/places";

const Places = () => {
    document.title = "İşletmeler | Sipyo";

    const [data, setData] = useState([]);
    const [managers, setManagers] = useState([]);
    const [filter, setFilter] = useState(null);
    const [modal, setModal] = useState(false);

    const loadPlaces = () => {
        loadPlacesService(filter).then((res) => {
            res = res?.data;
            console.log(res)
            if (!res?.errors) {
                setData(res?.data?.places);
            }
        })
    }

    useEffect(() => {
        loadPlaces(filter);
        
    }, []);

    const checkParameters = (managerParameters, object) => {        
        for (var parameter of managerParameters) {
            if (!object?.hasOwnProperty(parameter.value)) {
                toast.error(`Lütfen ${parameter.label} alanını doldurunuz!`);

                return false;
            }
        }

        return true;
    }

    return (
        <React.Fragment>
            <div className="page-content" style={{ borderRadius: "7px" }}>
                <Container fluid={true} style={{
                    background: "#3a3a3a",
                    height: "75px",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                }}>
                    <Row gutter={16} justify="space-between" align="middle" style={{ height: "75px" }}>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <Input.Search
                                placeholder="İsim ile ara..."
                                size="large"
                                onSearch={(value) => {
                                    if (value && value !== "") {
                                        setFilter({ ...filter, name: value });

                                    } else {
                                        delete filter.name;
                                        setFilter(filter);

                                    }
                                    loadPlaces();
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <div style={{ float: "right" }}>
                                <Button
                                    onClick={() => setModal({ manager: {} })}
                                    icon={<PlusOutlined />}
                                    style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                                    size="large"
                                >
                                    Yeni İşletme Ekle
                                </Button>

                            </div>
                        </Col>
                    </Row>
                </Container>

                <DataTable
                    paginationComponentOptions={{ noRowsPerPage: false }}
                    noHeader
                    //pagination
                    columns={PlaceColumns(setModal, loadPlaces, filter)}

                    paginationPerPage={10}
                    className="react-dataTable"
                    sortIcon={<CaretDownOutlined />}
                    //paginationDefaultPage={currentPage}
                    //paginationComponent={DatatableCustomPagination}
                    data={data}
                />
            </div>

            <Modal
                isOpen={!!modal}
                toggle={() => {
                    setModal(false);
                }}
                className="modal-dialog-centered modal-lg"
            >
                <ModalHeader>
                    {modal?.id ? "İşletme Güncelle" : "İşletme Ekle"}
                </ModalHeader>
                <ModalBody>
                    <Row gutter={12}>
                        <Col xs={12} md={12}>
                            <Label>İşletme İsmi</Label>
                            <Input defaultValue={modal?.name} placeholder="İşletme İsmi" onChange={(e) => setModal({ ...modal, name: e.target.value })} />
                        </Col>
                        <Col xs={12} md={12}>
                            <Label>Adres</Label>
                            <Input defaultValue={modal?.address} placeholder="Adres" onChange={(e) => setModal({ ...modal, address: e.target.value })} />
                        </Col>

                        {
                            !modal?.id ? <>
                                <Col xs={24} md={24}>
                                    <hr />
                                    <Label><b>Yönetici Bilgileri</b></Label>
                                </Col>

                                <Col xs={12} md={12}>
                                    <Label>İsim Soyisim</Label>
                                    <Input defaultValue={modal?.manager?.fullname} placeholder="İsim Soyisim" onChange={(e) => setModal({ ...modal, manager: { ...modal.manager, fullname: e.target.value } })} />
                                </Col>
                                <Col xs={12} md={12}>
                                    <Label>E-Posta</Label>
                                    <Input defaultValue={modal?.manager?.email} placeholder="E-Posta" onChange={(e) => setModal({ ...modal, manager: { ...modal.manager, email: e.target.value } })} />
                                </Col>
                                <Col xs={12} md={12}>
                                    <Label>Entegrasyonlar</Label>
                                    <Row>
                                        <Col xs={24} md={24}>
                                            <span>
                                                <Checkbox defaultChecked={modal?.digitalMenu} placeholder="Dijital Menü" onChange={(e) => setModal({ ...modal, digitalMenu: e.target.checked })} style={{ marginRight: "10px" }} />
                                                Dijital Menü
                                            </span>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <span>
                                                <Checkbox defaultChecked={modal?.marketplace} placeholder="Pazaryeri Entegrasyonu" onChange={(e) => setModal({ ...modal, marketplace: e.target.checked })} style={{ marginRight: "10px" }} />
                                                Pazaryeri Entegrasyonu
                                            </span>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <span>
                                                <Checkbox defaultChecked={modal?.courierTracking} placeholder="Kurye Takip Entegrasyonu" onChange={(e) => setModal({ ...modal, courierTracking: e.target.checked })} style={{ marginRight: "10px" }} />
                                                Kurye Takip Entegrasyonu
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} md={12}>
                                    <Label>Şifre</Label>
                                    <Input placeholder="Şifre" onChange={(e) => setModal({ ...modal, manager: { ...modal.manager, password: e.target.value } })} />
                                </Col>
                            </> : null
                        }

                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                        onClick={() => {
                            if (modal?.id) {
                                modal.placeId = modal.id;
                                if (typeof modal.manager == "object") {
                                    modal.manager = modal?.manager?.id;
                                }
                                modal.brand = modal?.brand?.id;

                                delete modal.id;
                                delete modal.createdAt;
                                delete modal.addedBy;

                                updatePlaceService(modal).then((res) => {
                                    if (!res.data.errors) {
                                        console.log(res.data.data);

                                        toast.success("İşletme Güncellendi.");

                                        setModal(null);
                                        loadPlaces(filter);
                                    }
                                })
                            } else {
                                if (!checkParameters([{value: "name", label: "İşletme İsmi"}], modal)) { return; }
                                if (!checkParameters([{value: "fullname", label: "İsim Soyisim"}, {value: "email", label: "E-Posta"}, {value: "password", label: "Şifre"}], modal?.manager)) { return; }

                                createPlaceService(modal).then((res) => {
                                    if (!res.data.errors) {
                                        console.log(res.data.data);

                                        toast.success("İşletme Oluşturuldu.");

                                        setModal(null);
                                        loadPlaces(filter);
                                    }
                                })
                            }
                        }}
                    >
                        {modal?.id ? "İşletmeyi Güncelle" : "İşletme Ekle"}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default Places;
