import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";
import Admins from "../Pages/Admins";
import Roles from "../Pages/Roles";
import BrandUsers from "../Pages/BrandUsers";
import Places from "../Pages/Places";
import MenuManagement from "../Pages/MenuManagement";
import TableManagement from "../Pages/TableManagement";


const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },
  
  // ! Admins
  { path: "/admins", component: <Admins /> },

  // ! Brand Users
  { path: "/brand-users", component: <BrandUsers /> },

  // ! Places
  { path: "/places", component: <Places /> },
  
  /*// ! Menu Management
  { path: "/menu-management", component: <MenuManagement /> },
  
  // ! Table Management
  { path: "/table-management", component: <TableManagement /> },*/

  // ! Roles
  { path: "/roles", component: <Roles /> },

  // Profile
  { path: "/userprofile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

];

export { authProtectedRoutes, publicRoutes };
