import axios from "../lib/axios";
const user = JSON.parse(localStorage.getItem("authUser"));

export const loadRolesService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            query{roles${form && Object?.values(form)?.length > 0 ? `(
                ${JSON.stringify(form)
                    ?.replaceAll("{", "")
                    ?.replaceAll("}", "")
                    ?.replaceAll('":', ":")
                    ?.replaceAll(',"', ",")
                    .slice(1)}
            )`: ""}{
                id
                title
                admin {
                    read
                    write
                    create
                    delete
                }
                role {
                    read
                    write
                    create
                    delete
                }
                createdAt
            }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const createRoleService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{createRole
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                ?.replaceAll('{"', "{")
                .slice(1)
                .slice(0, -1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const updateRoleService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{updateRole
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                ?.replaceAll('{"', "{")
                .slice(1)
                .slice(0, -1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const deleteRoleService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{deleteRole
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                ?.replaceAll('{"', "{")
                .slice(1)
                .slice(0, -1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}