import axios from "../lib/axios";
const user = JSON.parse(localStorage.getItem("authUser"));

export const loadAreasService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            query{areas${form && Object?.values(form)?.length > 0 ? `(
                ${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)}
            )`:""}{
                id
                name
                tables { id name }
                place { id name }
                addedBy { id fullname }
                createdAt
            }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const createAreaService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{createArea
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                ?.replaceAll('{"', "{")
                .slice(1)
                .slice(0, -1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const updateAreaService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{updateArea
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                ?.replaceAll('{"', "{")
                .slice(1)
                .slice(0, -1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const deleteAreaService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{deleteArea
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}