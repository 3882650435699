import axios from "../lib/axios";
const user = JSON.parse(localStorage.getItem("authUser"));

export const loadMenuCategoriesService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            query{menuCategories${form && Object?.values(form)?.length > 0 ? `(
                ${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)}
            )`:""}{
                id
                name
                image
                digitalMenu
                subcategory
                items {
                    id
                    name
                    description
                    price
                    tax
                    code
                    image
                    digitalMenu
                    portion
                    allergy
                    portions {
                        title
                        menuTitle
                        price
                        defaultPortion
                        listPortion
                    }
                    allergies {
                        corn
                        chocolate
                        fishAndSeaProducts
                        milkProducts
                        gluten
                        nuts
                        egg
                        peanut
                        legume
                    }
                    category {id name digitalMenu}
                    subcategory {id name digitalMenu}
                    createdAt
                }
                subCategories {
                    id
                    name
                    image
                    digitalMenu
                    items {
                        id
                        name
                        description
                        price
                        tax
                        code
                        image
                        digitalMenu
                        portion
                        allergy
                        portions {
                            title
                            menuTitle
                            price
                            defaultPortion
                            listPortion
                        }
                        allergies {
                            corn
                            chocolate
                            fishAndSeaProducts
                            milkProducts
                            gluten
                            nuts
                            egg
                            peanut
                            legume
                        }
                        category {id name digitalMenu}
                        subcategory {id name digitalMenu}
                        createdAt
                    }
                }
                place { id name }
                addedBy { id fullname }
                createdAt
            }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const createMenuCategoryService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{createMenuCategory
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                ?.replaceAll('{"', "{")
                .slice(1)
                .slice(0, -1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const updateMenuCategoryService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{updateMenuCategory
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                ?.replaceAll('{"', "{")
                .slice(1)
                .slice(0, -1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const deleteMenuCategoryService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{deleteMenuCategory
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}