import axios from "../lib/axios";
const user = JSON.parse(localStorage.getItem("authUser"));

export const loginAdminService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{loginAdmin(
                ${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)}
            ){
                id
                fullname
                email
                token
            }}
            `,
    });

}

export const verifyTokenService = () => {
    return axios.post("/api/graphql", {
        query: `
            mutation{verifyToken{
                verified
            }}
            `,
    }, {
        headers: {
            
        }
    });

}