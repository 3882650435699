import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { Button } from 'reactstrap'
import { confirmable, createConfirmation } from "react-confirm";

const Confirmation = ({
  okLabel = "Evet",
  cancelLabel = "Hayır",
  title,
  confirmation,
  proceed,
  enableEscape = true
}) => {
  const [show, setShow] = useState(true);
  return (
    <div className="static-modal">
      <Modal
        animation={false}
        show={show}
        onHide={() => {
          setShow(false);
          proceed(false);
        }}
        backdrop={enableEscape ? true : "static"}
        keyboard={enableEscape}
      >
        <Modal.Header>
          <Modal.Title>{confirmation}</Modal.Title>
        </Modal.Header>
        {/*<Modal.Body>{confirmation}</Modal.Body>*/}
        <Modal.Footer>
          <Button color="outline" style={{ outline: "1px solid gray" }} onClick={() => {
            setShow(false);
            proceed(false);
          }}>{cancelLabel}</Button>
          <Button
            className="button-l"
            onClick={() => {
              setShow(false);
              proceed(true);
            }}
            style={{ background: "#c72328" }}
          >
            {okLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

Confirmation.propTypes = {
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool
};

export function confirm(
  confirmation,
  proceedLabel = "Evet",
  cancelLabel = "Hayır",
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    proceedLabel,
    cancelLabel,
    ...options
  });
}
