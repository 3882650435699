import axios from "../lib/axios";
const user = JSON.parse(localStorage.getItem("authUser"));

export const loadPlacesService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            query{places${form && Object?.values(form)?.length > 0 ? `(
                ${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)}
            )`:""}{
                id
                name
                address
                manager {
                    id
                    fullname
                }
                addedBy {
                    id
                    fullname
                }
                createdAt
            }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const createPlaceService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{createPlace
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                ?.replaceAll('{"', "{")
                .slice(1)
                .slice(0, -1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const updatePlaceService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{updatePlace
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                ?.replaceAll('{"', "{")
                .slice(1)
                .slice(0, -1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const deletePlaceService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{deletePlace
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}