import axios from "../lib/axios";
const user = JSON.parse(localStorage.getItem("authUser"));

export const uploadFileService = async (file, onUploadProgress) => {
  const user = JSON.parse(localStorage.getItem("authUser"));
  const formData = new FormData();
  formData.append("file", file);

  return fetch(process.env.REACT_APP_LIVE_URL + "/api/upload/file", {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });

};
  