import { DeleteOutlined, SettingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { toast } from "react-toastify";
import { deleteRoleService } from "../../../services/roles";
import { confirm } from "../../../components/confirm";

export const RoleColumns = (setModal, loadRoles, filter) => {
    return [
        {
            name: "Başlık",
            sortable: true,
            sortField: "title",
            minWidth: "300px",
            selector: (row) => row.title,
        },
        {
          name: "Oluşturma Tarihi",
          sortable: true,
          sortField: "date",
          minWidth: "200px",
          selector: (row) => new Date(parseInt(row.createdAt)).toLocaleDateString(),
        },
        {
            name: "Aksiyonlar",
            sortable: true,
            sortField: "name",
            minWidth: "300px",
            sortable: false,
            selector: (row) => (
                <div>
                    <Button onClick={() => setModal(row)} color="#c72328" icon={<SettingOutlined color="#c72328" />} style={{ marginLeft: "5px" }} />
                    <Button onClick={async () => {
                        if (await confirm("Rolü silmek istediğine emin misin?")) {
                            deleteRoleService({ roleId: row.id }).then((res) => {
                                if (!res.data.errors) {
                                    console.log(res.data.data);

                                    toast.warn("Rol Silindi.");

                                    setModal(null);
                                    loadRoles(filter);
                                }
                            })
                        }
                    }} color="#c72328" icon={<DeleteOutlined color="#c72328" />} style={{ marginLeft: "5px", backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }} />
                </div>
            ),
        },
    ];
};