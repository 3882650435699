import axios from "../lib/axios";
const user = JSON.parse(localStorage.getItem("authUser"));

export const loadBrandUsersService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            query{brandUsers${form && Object?.values(form)?.length > 0 ? `(
                ${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)}
            )`:""}{
                id
                fullname
                email
                role {
                    id
                    title
                }
                token
                createdAt
            }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const registerBrandUserService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{registerBrandUser
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const editBrandUserService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{editBrandUser
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}

export const deleteBrandUserService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{deleteBrandUser
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {
            
        }
    });

}