const SidebarData = [
    {
        label: "Genel",
        isMainMenu: true,
    },
    {
        label: "Ana Sayfa",
        //icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        issubMenubadge: true,
        bgcolorhex: "#c72328",
        badgeValue: "3"
    },
    {
        label: "Kullanıcı Yönetimi",
        //icon: "mdi mdi-home-variant-outline",
        url: "/admins",
        issubMenubadge: true,
    },
    {
        label: "Rol Yönetimi",
        //icon: "mdi mdi-home-variant-outline",
        url: "/roles",
        issubMenubadge: true,
    },
    {
        label: "İşletmeler",
        //icon: "mdi mdi-home-variant-outline",
        url: "/places",
        issubMenubadge: true,
    },/*
    {
        label: "İşletme",
        //icon: "mdi mdi-email-outline",
        subItem: [
            {
                sublabel: "Ürün Yönetimi",
                //icon: "mdi mdi-home-variant-outline",
                link: "/menu-management",
            },
            {
                sublabel: "Masa Yönetimi",
                //icon: "mdi mdi-home-variant-outline",
                link: "/table-management",
            },
        ],
    },*/
]
export default SidebarData;