import { DeleteOutlined, SettingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { deleteBrandUserService } from "../../../services/brand-user";
import { toast } from "react-toastify";
import { confirm } from "../../../components/confirm";

export const BrandUserColumns = (setModal, loadBrandUsers, filter) => {
    return [
        {
            name: "İsim soyisim",
            sortable: true,
            sortField: "fullname",
            minWidth: "300px",
            selector: (row) => row.fullname,
        },
        {
            name: "E-Posta",
            sortable: true,
            sortField: "email",
            minWidth: "300px",
            selector: (row) => row.email,
        },
        {
            name: "Oluşturma Tarihi",
            sortable: true,
            sortField: "date",
            minWidth: "200px",
            selector: (row) => new Date(parseInt(row.createdAt)).toLocaleDateString(),
        },
        {
            name: "Aksiyonlar",
            sortable: true,
            sortField: "name",
            minWidth: "300px",
            sortable: false,
            selector: (row) => (
                <div>
                    <Button onClick={() => setModal(row)} color="#c72328" icon={<SettingOutlined color="#c72328" />} style={{ marginLeft: "5px" }} />
                    <Button onClick={async () => {
                        if (await confirm("Kullanıcıyı silmek istediğine emin misin?")){
                            deleteBrandUserService({ adminId: row.id }).then((res) => {
                                if (!res.data.errors) {
                                    console.log(res.data.data);

                                    toast.warn("Kullanıcı Silindi.");

                                    setModal(null);
                                    loadBrandUsers(filter);
                                }
                            })
                        }
                    }} color="#c72328" icon={<DeleteOutlined color="#c72328" />} style={{ marginLeft: "5px", backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }} />
                </div>
            ),
        },
    ];
};