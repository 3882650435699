import React, { useState } from "react";
import { Button, Col, Input, Row } from 'antd';
import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, Container, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input as ReactstrapInput } from "reactstrap";
import DataTable from "react-data-table-component";
import { RoleColumns } from './columns'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { createRoleService, loadRolesService, updateRoleService } from "../../services/roles";

const Roles = () => {
    document.title = "Roller | Sipyo";

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(null);
    const [modal, setModal] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState(["admin", "role"]);

    const loadRoles = () => {
        loadRolesService(filter).then((res) => {
            res = res?.data;
            console.log(res)
            if (!res?.errors) {
                setData(res?.data?.roles);
            }
        })
    }

    useEffect(() => {
        loadRoles(filter);
    }, []);


    return (
        <React.Fragment>
            <div className="page-content" style={{ borderRadius: "7px" }}>
                <Container fluid={true} style={{
                    background: "#3a3a3a",
                    height: "75px",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                }}>
                    <Row gutter={16} justify="space-between" align="middle" style={{ height: "75px" }}>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <Input.Search
                                placeholder="İsim ile ara..."
                                size="large"
                                onSearch={(value) => {
                                    if (value && value !== "") {
                                        setFilter({...filter, title: value});
                                        
                                    } else {
                                        delete filter.title;
                                        setFilter(filter);

                                    }
                                    loadRoles();
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <div style={{ float: "right" }}>
                                <Button
                                    onClick={() => {
                                        const initialModalState = {};
                                        selectedRoles.forEach(role => {
                                            initialModalState[role] = {
                                                read: false,
                                                write: false,
                                                create: false,
                                                delete: false,
                                            };
                                        });
                                        setModal(initialModalState);

                                    }}
                                    icon={<PlusOutlined />}
                                    style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                                    size="large"
                                >
                                    Yeni Rol Ekle
                                </Button>

                            </div>
                        </Col>
                    </Row>
                </Container>

                <DataTable
                    paginationComponentOptions={{ noRowsPerPage: false }}
                    noHeader
                    //pagination
                    columns={RoleColumns(setModal, loadRoles, filter)}

                    paginationPerPage={10}
                    className="react-dataTable"
                    sortIcon={<CaretDownOutlined />}
                    //paginationDefaultPage={currentPage}
                    //paginationComponent={DatatableCustomPagination}
                    data={data}
                />
            </div>

            <Modal
                isOpen={!!modal}
                toggle={() => {
                    setModal(false);
                }}
                className="modal-dialog-centered modal-lg"
            >
                <ModalHeader>
                    {modal?.id ? "Rol Güncelle" : "Rol Ekle"}
                </ModalHeader>
                <ModalBody>
                    <Row gutter={12}>
                        <Col xs={24} md={24}>
                            <Label>Başlık</Label>
                            <Input defaultValue={modal?.title} placeholder="Başlık" onChange={(e) => setModal({ ...modal, title: e.target.value })} />
                        </Col>
                    </Row>

                    <Table>
                        <thead>
                            <th></th>
                            <th>Read</th>
                            <th>Write</th>
                            <th>Create</th>
                            <th>Delete</th>
                        </thead>
                        <tbody>
                            {selectedRoles.map(role => (
                                <tr key={role}>
                                    <td>{role.charAt(0).toUpperCase() + role.slice(1)}</td>
                                    <td>
                                        <ReactstrapInput
                                            className="reactstrap-input"
                                            type="checkbox"
                                            width={20}
                                            checked={modal?.[role]?.read}
                                            onChange={(e) =>
                                                setModal({
                                                    ...modal,
                                                    [role]: {
                                                        ...modal?.[role],
                                                        read: e.target.checked,
                                                    },
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <ReactstrapInput
                                            className="reactstrap-input"
                                            type="checkbox"
                                            width={20}
                                            checked={modal?.[role]?.write}
                                            onChange={(e) =>
                                                setModal({
                                                    ...modal,
                                                    [role]: {
                                                        ...modal?.[role],
                                                        write: e.target.checked,
                                                    },
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <ReactstrapInput
                                            className="reactstrap-input"
                                            type="checkbox"
                                            width={20}
                                            checked={modal?.[role]?.create}
                                            onChange={(e) =>
                                                setModal({
                                                    ...modal,
                                                    [role]: {
                                                        ...modal?.[role],
                                                        create: e.target.checked,
                                                    },
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <ReactstrapInput
                                            className="reactstrap-input"
                                            type="checkbox"
                                            width={20}
                                            checked={modal?.[role]?.delete}
                                            onChange={(e) =>
                                                setModal({
                                                    ...modal,
                                                    [role]: {
                                                        ...modal?.[role],
                                                        delete: e.target.checked,
                                                    },
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                        onClick={() => {
                            if (modal?.id) {
                                modal.roleId = modal.id;
                                delete modal.id;
                                delete modal.createdAt;

                                updateRoleService(modal).then((res) => {
                                    if (!res.data.errors) {
                                        console.log(res.data.data);

                                        toast.success("Rol Güncellendi.");

                                        setModal(null);
                                        loadRoles(filter);
                                    }
                                })
                            } else {
                                createRoleService(modal).then((res) => {
                                    if (!res.data.errors) {
                                        console.log(res.data.data);

                                        toast.success("Rol Oluşturuldu.");

                                        setModal(null);
                                        loadRoles(filter);
                                    }
                                })
                            }
                        }}
                    >
                        {modal?.id ? "Rolyı Güncelle" : "Rol Ekle"}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default Roles;
